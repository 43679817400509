import Vue from 'vue'

import MobileMenu from '../components/MobileMenu.vue'
import NavigationItem from '../components/NavigationItem.vue'
import TransitionHeight from '../components/TransitionHeight.vue'
import Slideshow from '../components/Slideshow.vue'
import SlideshowBlog from '../components/SlideshowBlog.vue'
import LazyImage from '../components/LazyImage.vue'
import Newsletter from '../components/Newsletter.vue'
import MovingImageByAspect from '../components/MovingImageByAspect.vue'
import ShopNavigation from '../components/ShopNavigation.vue'
import OverlayGallery from '../components/OverlayGallery.vue'
import OverlayNewsletter from '../components/OverlayNewsletter.vue'

import Utils from '../scripts/utils'
import Feat from '../scripts/feat'
import store from '../store'

Vue.component('MobileMenu', MobileMenu)
Vue.component('NavigationItem', NavigationItem)
Vue.component('TransitionHeight', TransitionHeight)
Vue.component('Slideshow', Slideshow)
Vue.component('SlideshowBlog', SlideshowBlog)
Vue.component('LazyImage', LazyImage)
Vue.component('Newsletter', Newsletter)
Vue.component('OverlayGallery', OverlayGallery)

export default class App {
  bus: any = {} //Data provided on-page via script tags
  data: any = {} //Data provided on-page via script tags
  utils: any = {} //Data provided on-page via script tags
  feat: any = {} //Data provided on-page via script tags
  state: any = {} //State used on this page
  store: any = {} //State shared between pages
  view: any = {} //Functions that display state
  mutator: any = {} //Functions that mutate state
  event: any = {} //Event listeners/publishers

  constructor(d: any) {
    this.data = d //Data provided on-page via script tags

    // Create event bus
    this.bus = new Vue()

    //Utility functions
    this.utils = new Utils(this)
    this.feat = new Feat(this)

    // Create Vue instance
    new Vue({
      el: '#app',
      components: {
        ShopNavigation,
        MovingImageByAspect,
        OverlayNewsletter,
      },
      data: () => {
        return {
          d: this.data,
          s: this.state,
          v: this.view,
          m: this.mutator,
          ls: this.store,
          bus: this.bus,
        }
      },
      delimiters: ['{~', '~}'],
      methods: {
        emit(path, data = {}) {
          console.log('emit', path)
          this.bus.$emit(path, data)
        },
        instance() {
          return this
        },
        moveAppCursor: ($event: any) => {
          if (this.data.template.indexOf('index') > -1) return
          return this.mutator.index.moveCursor($event, this.state, this.utils)
        },
      },
      computed: {
        appClass() {
          return [this.v.app.showClass(this.d, this.s, this.v), { 'alert-active': this.s.app.alert }]
        },
      },
      mounted() {
        this.$store.commit('app/setSettings', this.d.settings)
      },
      provide() {
        return {
          appState: this.$store.state.app,
        }
      },
      store,
    })

    this.bus.$emit('vue/loaded', {})
  }
}

window.App = new App(window._data)
