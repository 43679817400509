export default {
  active_options: '',
  selected_variant: -1,
  selected_quantity: 1,
  selection_group: '',
  selected_options: [],
  selected_slide: 0,
  related: [],
  notify_email: '',
  message: '',
  error: false,
  images: [],
  content_bottom: -1,
  w2w: [],
  user_selected: false,
}
