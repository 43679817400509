import Base from '../../base'

export default class Default extends Base {
  init() {
    this.bus.$on('cart/item/add', (d) => this.add(d))
    this.bus.$on('cart/item/remove', (d) => this.remove(d))
    this.bus.$on('cart/item/update', (d) => this.update(d))
  }

  add(d) {
    const id = d.id
    const quantity = typeof d.quantity != 'undefined' ? d.quantity : 1

    this.bus.$emit('cart/item/adding')
    this.u.shopify.cart.add({
      data: {
        quantity: quantity,
        id: id,
      },
      callback: (_d) => {
        this.refresh('cart/item/added')
        this.m.overlay.setOverlay(this.s, 'cart')
      },
    })
  }

  remove(d) {
    const id = d.id
    this.bus.$emit('cart/item/removing')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: 0,
        },
      },
      callback: (_d) => {
        this.refresh('cart/item/removed')
      },
    })
  }

  update(d) {
    const id = d.id
    const qty = d.quantity
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: qty,
        },
      },
      callback: (d) => {
        this.refresh('cart/item/updated')
      },
    })
  }

  updateAttributes(attributes) {
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        attributes: attributes,
      },
      callback: (d) => {
        this.refresh('cart/item/updated')
      },
    })
  }

  refresh(path) {
    //Refresh the cart contents
    this.u.shopify.cart.get({
      callback: (d) => {
        this.d.cart = d
        this.bus.$emit(path)
      },
    })
  }
}
