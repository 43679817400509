<template>
  <div class="MovingImageByAspect">
    <template v-if="isLandscape !== undefined">
      <MovingImage v-if="!isLandscape && portraitSource" autoplay :source="portraitSource" />
      <MovingImage v-else-if="isLandscape && landscapeSource" autoplay :source="landscapeSource" />
    </template>
  </div>
</template>

<script>
import MovingImage from './MovingImage.vue'
import resize from '../mixins/resize'

export default {
  components: { MovingImage },

  mixins: [resize],

  props: {
    landscapeSource: {
      type: String,
      default: '',
    },
    portraitSource: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isLandscape: undefined,
    }
  },

  computed: {
    show() {
      return (this.isLandscape && this.landscapeSource) || (!this.isLandscape && this.portraitSource)
    },
  },

  methods: {
    onResize() {
      this.isLandscape = window.matchMedia('(orientation: landscape)').matches
    },
  },
}
</script>

<style lang="scss"></style>
