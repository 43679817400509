import _ from 'lodash'
import Base from '../../base'

export default class Default extends Base {
  init() {
    this.bus.$on('vue/loaded', (d) => {
      this.setSubmenu()
    })
  }

  setSubmenu() {
    //Link areas to their submenus
    const map = [
      {
        key: 'shop',
        paths: ['/collections/', '/products/'],
        exclude_paths: [],
      },
      {
        key: 'campaigns',
        paths: ['/blogs/campaigns/'],
        exclude_paths: [],
      },
      {
        key: 'info',
        paths: ['/pages/'],
        exclude_paths: ['/pages/stockists', '/pages/contact'],
      },
    ]

    const active = _.find(map, (v) => {
      return (
        _.find(v.paths, (p) => {
          return window.location.href.indexOf(p) > -1
        }) &&
        !_.find(v.exclude_paths, (p) => {
          return window.location.href.indexOf(p) > -1
        })
      )
    })

    if (active) {
      this.s.header.existing_submenu = active.key
      this.s.header.current_submenu = active.key
    }
  }
}
