<template>
  <a
    :href="link.url"
    class="ShopifyAnchor"
    :class="{
      active: link.active || link.current,
      childActive: link.child_active,
    }"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss"></style>
