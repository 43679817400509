import Base from '../../base'
import $ from 'jquery'

export default class Default extends Base {
  init() {
    this.bus.$on('vue/loaded', (d) => {
      this.tests(d)
    })
    this.bus.$on('vue/loaded', (d) => {
      this.initAlert(d)
    })
    this.bus.$on('scroll/top', (d) => {
      this.scrollToTop(d)
    })
    this.bus.$on('submenu/click', (d) => {
      this.submenuClick(d)
    })

    //Swatch overlay
    this.bus.$on('swatches', (s) => {
      this.s.overlay.current = 'swatches'
    })
  }

  initAlert() {
    const message = this.d.alert_message
    const storage = JSON.parse(window.localStorage.getItem('alert'))

    if (storage) {
      const expired = Date.now() - storage.closed < 1000 * 60 * 60 * 24 * 7
      if (expired && storage.message === message) return
    }

    this.s.app.alert = message ? true : false
  }

  tests() {
    if (window.matchMedia('(any-pointer: fine)').matches) {
      this.s.app.has_mouse = true
    }
    console.log('has mouse', this.s.app.has_mouse)
  }

  scrollToTop(d) {
    $('body').velocity('scroll', { offset: '0', mobileHA: false })
  }

  submenuClick(d) {
    //open if on desktop, else do nothing
    const has_mouse = this.s.app.has_mouse

    if (!has_mouse) {
      d.evt.preventDefault()
    }
  }
}
