import _ from 'lodash'

import Base from '../../base'

export default class Default extends Base {
  init() {
    this.bus.$on('vue/loaded', (d) => {
      this.loadStockists(d)
    })
  }

  loadStockists(path) {
    const stockists_element = document.getElementById('stockists_json')

    if (stockists_element) {
      const stockists_data = JSON.parse(stockists_element.innerHTML)
      const grouped_stockists = _.groupBy(stockists_data, 'group')

      this.s.stockists.stockists = [
        { regions: _.groupBy(grouped_stockists.america, 'region') },
        { regions: _.groupBy(grouped_stockists.international, 'region') },
        { regions: _.groupBy(grouped_stockists.online, 'region') },
      ]
    }
  }
}
