<template>
  <div class="newsletter-wrapper">
    <form
      class="form"
      :class="`${message == '' ? 'form--blank' : 'form--message'}`"
      method="get"
      @submit.prevent="onSubmit"
    >
      <input
        class="email"
        type="email"
        v-model="email"
        placeholder="Enter your email address"
        required
      />
      <div class="message" v-if="message != ''">{{ message }}</div>
      <button class="button">{{ buttonText }}</button>
    </form>
  </div>
</template>

<script>
import { subscribe } from 'klaviyo-subscribe'

export const KLAVIYO_LIST_ID = 'VL4B5H'

export default {
  data() {
    return {
      email: '',
      message: '',
      submitting: false,
      submitError: false,
      buttonText: 'Submit',
    }
  },

  computed: {
    submitText() {
      return this.submitting ? 'Submitting' : this.buttonText
    },
  },

  methods: {
    async onSubmit() {
      this.submitError = false
      try {
        this.submitting = true
        const response = await subscribe(KLAVIYO_LIST_ID, this.email)
        this.submitting = false

        if (!response.success) throw new Error(response.errors[0])

        this.email = ''
        this.message = 'Thanks for signing up!'
      } catch (exception) {
        this.submitError = true
        this.message = exception.message
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.newsletter-wrapper {
  .form {
    display: flex;
    justify-content: space-between;
    gap: 10px 80px;

    &--message {
    }

    @include respond-to(xl) {
      flex-direction: column;
    }

    .email {
      width: 180px;
      flex-grow: 1;
      /* padding-right: 70px; */
    }

    .button {
      width: 65px;
      /* top: 0; */
      /* right: 0; */
      /* position: absolute; */
    }
  }
}
</style>
