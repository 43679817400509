import _ from 'lodash'

export default {
  showCursorStyle: (data, state, view) => {
    const image = state.index.cursor

    if (!image) return {}

    const x = `${image.x + 5}px`
    const y = `${image.y + 5}px`

    const position = {
      left: 0, //`${image.x + 5}px`,
      top: 0, //`${image.y - 10}px`
      transform: `translateX(${x}) translateY(${y})`,
    }

    const current = state.index.current_index
    const src = data.index.cursors[current]
    const background = { 'background-image': `url('${src}')` }

    return _.merge(position, background)
  },
  showImageStyle: (data, state, key) => {
    const current = state.index.current_index
    const src = data.index[key][current]

    return `background-image:url('${src}')`
  },
  showImageCaption: (data, state, key) => {
    const current = state.index.current_index
    const caption = data.index.captions[current]

    return caption
  },
  getImageUrl: (data, state, key) => {
    const current = state.index.current_index
    const url = data.index.urls[current]

    return url
  },
}
