<template>
  <div class="overlay overlay-gallery" v-if="getActive()">
    <div class="pager" v-text="pager()"></div>
    <div class="close" @click="closeOverlay()">
      <div class="inner">
        <div class="slide" v-for="(slide, slide_idx) in slides" :key="`slide_${slide_idx}`">
          <img :src="showImage(slide.url, dimensions)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'

export default {
  props: ['slides', 'overlay', 'dimensions'],
  mounted: function () {
    this.init()
  },
  data() {
    return {
      ready: false,
      active: false,
      $overlay: -1,
      $slides: -1,
      current_slide: 0,
      scroll_top: 0,
      tops: [0],
    }
  },
  methods: {
    init() {
      console.log('init')
      this.ready = true
    },
    activate() {
      //Monitor scroll
      this.$overlay = $('.overlay.overlay-gallery')

      this.$overlay.on('scroll', (e) => {
        this.scroll_top = this.$overlay.scrollTop()
      })

      this.$slides = this.$overlay.find('.slide')
      this.tops = _.reverse(
        _.map(this.$slides, ($slide) => {
          return $($slide).offset().top
        })
      )

      this.active = true
    },
    closeOverlay() {
      this.active = false
      this.$emit('update:overlay', '')
    },
    showImage: (url, format) => {
      //Split url by '.'
      const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
      // Insert format in string
      return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
    },
    getActive() {
      //Run activate function if we need to
      if (this.ready == true && this.overlay == 'gallery') {
        if (!this.active) {
          setTimeout(() => {
            this.activate()
          }, 10)
        }

        return true
      }

      return false
    },
    pager() {
      if (!this.getActive()) return ''

      //find current slide
      const scroll_top = this.scroll_top
      let current_slide =
        this.tops.length -
        _.findIndex(this.tops, (v) => {
          return v < scroll_top
        })

      if (current_slide > this.tops.length) current_slide = 1

      return `VIEW ${current_slide}/${this.slides.length}`
    },
  },
}
</script>

<style lang="scss"></style>
