<template>
  <transition name="fade">
    <div class="overlay-newsletter" v-if="getActive">
      <div class="overlay-newsletter__inner">
        <div class="overlay-newsletter__image">
          <img :src="image" alt="Newsletter" />
        </div>
        <div class="overlay-newsletter__container">
          <div class="overlay-newsletter__heading">
            <h2 class="overlay-newsletter__title">{{ title }}</h2>
            <button class="overlay-newsletter__close" @click.prevent="close">Close</button>
          </div>
          <div class="overlay-newsletter__description">
            {{ description }}
          </div>
          <form class="overlay-newsletter__form" @submit.prevent="submit">
            <input
              class="overlay-newsletter__email"
              type="email"
              placeholder="Email address"
              v-model="email"
            />
            <button class="overlay-newsletter__submit">
              {{ message ? message : submitting ? 'Subscribing' : 'Subscribe' }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { computed, inject, ref, onMounted } from 'vue'
import { subscribe } from 'klaviyo-subscribe'
import { KLAVIYO_LIST_ID } from './Newsletter.vue'

const appState: any = inject('appState')

const title = computed(() => appState?.settings?.newsletter_popup_title)
const description = computed(() => appState?.settings?.newsletter_popup_description)
const image = computed(() => appState?.settings?.newsletter_popup_image)

const getActive = ref(false)
const submitting = ref(false)
const message = ref('')
const email = ref('')

onMounted(() => {
  if (localStorage.getItem('NEWSLETTER_POPUP_CLOSED') === 'true') {
    getActive.value = false
  } else {
    setTimeout(() => (getActive.value = true), 5000)
  }
})

const submit = async () => {
  try {
    submitting.value = true
    const response = await subscribe(KLAVIYO_LIST_ID, email.value)
    submitting.value = false

    if (!response.success) throw new Error(response.errors[0])

    email.value = ''
    message.value = 'Signed up!'

    setTimeout(close, 3000)
  } catch (exception) {
    message.value = exception.message
  }
}

const close = () => {
  getActive.value = false
  localStorage.setItem('NEWSLETTER_POPUP_CLOSED', 'true')
}
</script>

<style lang="scss">
@import '~/styles/frontend/typography.scss';

.overlay-newsletter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;

  &__inner {
    margin: auto;
    max-width: 490px;
    width: calc(100vw - 40px);
    background: #fffeea;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__container {
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  &__title {
    @include cx-uc;
  }

  &__close {
    @include serif;
  }

  &__description {
    padding-right: 20px;
    margin-bottom: auto;
    padding-bottom: 10px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__email {
    background: #fff;
    border: 1px solid #000;
    text-align: center;
    padding: 5px 10px;
    font-size: 10px;
    margin-bottom: 5px;
  }

  &__submit {
    color: #fff;
    background: #000;
    border: 1px solid #000;
    text-align: center;
    padding: 5px 10px;
    font-size: 10px;

    &:hover {
      background: #fff;
      color: #000;
    }
  }
}
</style>
