<template>
  <div class="NavigationItem">
    <ShopifyAnchor v-if="link.levels === 0" :link="link" class="link" :class="{ topLevel }">
      <Italicize v-if="depth === 0" :force="active">{{ link.title }}</Italicize>
      <span v-else>{{ link.title }}</span>
    </ShopifyAnchor>
    <template v-else>
      <AccordionItem
        v-if="interactive"
        :id="_uid"
        :active="active"
        :event="event"
        :heading="link.title"
        :select="select"
      >
        <template #button>
          <ShopifyAnchor :link="link" class="link">
            <Italicize :force="active">{{ link.title }}</Italicize>
          </ShopifyAnchor>
        </template>
        <div class="links">
          <NavigationItem
            v-for="(sublink, i) in link.links"
            :key="i + link.levels + link.url"
            :link="sublink"
            :depth="depth + 1"
          />
        </div>
      </AccordionItem>
      <div v-else>
        <ShopifyAnchor :link="link" class="link">
          <Italicize v-if="depth === 0" :force="active">{{ link.title }}</Italicize>
          <span v-else>{{ link.title }}</span>
        </ShopifyAnchor>
        <div class="links">
          <NavigationItem
            v-for="(sublink, i) in link.links"
            :key="i + link.levels + link.url"
            :link="sublink"
            :depth="depth + 1"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// TODO:
//  Create render function to clean up repeated template elements
//  Add slot for anchor inner

import AccordionItem from './AccordionItem.vue'
import Italicize from './Italicize.vue'
import ShopifyAnchor from './ShopifyAnchor.vue'

export default {
  name: 'NavigationItem',

  components: {
    AccordionItem,
    Italicize,
    ShopifyAnchor,
  },

  props: {
    depth: {
      type: Number,
      default: 0,
    },
    event: {
      type: String,
      default: 'click',
    },
    interactive: {
      type: Boolean,
      default: true,
    },
    link: {
      type: Object,
      required: true,
    },
    select: {
      type: Function,
      default: () => {},
    },
    selected: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    active() {
      return this.selected === this._uid
    },
    topLevel() {
      return this.depth === 0
    },
  },
}
</script>

<style lang="scss"></style>
