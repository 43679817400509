<template>
  <span class="Italicize" :class="{ force, prevent }" :style="{ '--italic-content': text }">
    <span class="roman">{{ text }}</span>
    <span aria-hidden="true" class="italic">{{ text }}</span>
  </span>
</template>

<script>
export default {
  props: {
    force: {
      type: Boolean,
      default: false,
    },
    prevent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    text() {
      if (!this.$slots.default[0] && this.$slots.default[0].text) return ''
      return this.$slots.default[0].text
    },
  },
}
</script>

<style lang="scss">
.Italicize {
  display: grid;
  grid-template: 1fr / 1fr;

  .roman,
  .italic {
    grid-area: 1 / 1;
  }

  .italic {
    font-style: italic;
    letter-spacing: 0.03em;
    opacity: 0;
  }
}

.force {
  .roman {
    opacity: 0;
  }

  .italic {
    opacity: 1;
  }
}

@media (hover: hover) {
  .Italicize:not(.prevent):hover {
    .roman {
      opacity: 0;
    }

    .italic {
      opacity: 1;
    }
  }
}
</style>
