import moment from 'moment'
import _ from 'lodash'

export default {
  isToggleActive: (state, key) => {
    const toggle = _.find(state.app.toggles, { key: key })
    return !!toggle ? toggle.val : false
  },
  showClass: (data, state, view) => {
    const classes = { loaded: true }

    _.merge(classes, view.app.getOverlayClass(data, state, view))

    _.merge(classes, view.app.getHeaderClass(data, state, view))

    if (!_.isEqual(state.app.classes, classes)) state.app.classes = classes

    return classes
  },
  getOverlayClass: (_, state) => {
    const classes = {}
    const overlay = state.overlay.current
    if (overlay !== '') classes[`overlay-${overlay}`] = true

    return classes
  },
  getHeaderClass: (_, state) => {
    const classes = {}
    const scroll_top = state.window.scroll.top.current

    if (scroll_top > 10) classes['header-shadow'] = true

    return classes
  },
  showImage: (url, format = '600x800_crop_center') => {
    const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1

    return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
  },
  showInnerClass: (state) => {
    return {
      blur: state.overlay.current != '',
    }
  },
  showDate: (timestamp) => {
    const date = moment(timestamp * 1000)

    return date.format('DD/MM/YY')
  },
}
