import fx from 'money'
import _ from 'lodash'

export default {
  showMoney: (state, view, cents) => {
    fx.rates = window.Currency.rates
    const formatSymbol = view.currency.formatSymbol
    const base = state.currency.base
    const code = state.currency.code
    let amount = (cents / 100).toFixed(2)

    // var original = amount;

    let symbol = '$'
    if (code == 'EUR') symbol = '€'
    if (code == 'GBP') symbol = '₤'
    if (code == 'YEN') symbol = '¥'

    if (base !== code) {
      amount = fx.convert(amount, { from: code, to: base }).toFixed(2)
    }

    amount = amount.replace('.00', '')
    amount = code === 'JPY' || code === 'KRW' ? Math.round(amount) : amount
    const suffix = code == 'USD' || code === 'JPY' || code === 'KRW' ? '' : code

    return `${formatSymbol(code)}${amount}${suffix}`
  },
  showPrice: (state, view, data, code) => {
    const showMoney = view.currency.showMoney
    let formatted_original = false
    const formatted_price = showMoney(state, view, data.price, code) //.toString().replace('.00', '').replace('.0', '');

    //If original price exists
    if (data.original != null && data.original > data.price)
      formatted_original = showMoney(state, view, data.original)

    if (formatted_original) {
      return `<span class="sale">${formatted_price}</span>, <span class="original">${formatted_original}</span>`
    }

    return `${formatted_price}`
  },
  showCurrency: (view, code) => {
    const formatSymbol = view.currency.formatSymbol

    return `${formatSymbol(code)} ${code}`
  },
  formatSymbol: (code) => {
    let symbol = '$'
    if (code == 'EUR') symbol = '€'
    if (code == 'GBP') symbol = '₤'
    if (code == 'KRW') symbol = '₩'
    if (code == 'JPY') symbol = '¥'
    return symbol
  },
  formatCurrencies: (state, view) => {
    const current_code = state.currency.code
    const showCurrency = view.currency.showCurrency
    const codes = ['AUD', 'USD', 'GBP', 'JPY', 'KRW']

    return _.chain(codes)
      .map((c) => {
        return {
          code: c,
          name: showCurrency(view, c),
        }
      })
      .filter((c) => {
        return c.code !== current_code
      })
      .value()
  },
}
