export default class Default {
  constructor(main) {
    this.b = main

    this.d = main.data
    this.s = main.state
    this.v = main.view
    this.m = main.mutator
    this.e = main.event
    this.ls = main.store

    this.bus = main.bus

    this.u = main.utils

    this.init()
  }

  init() {}
}
