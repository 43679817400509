import $ from 'jquery'

export default {
  setOverlay: (state, overlay = '') => {
    state.overlay.previous = state.overlay.current
    state.overlay.current = overlay

    //Add to body tag
    $('#body').attr('data-overlay', overlay == '' ? 'none' : overlay)
  },
  toggleMMenuShop: (state) => {
    state.overlay.mmenu.shop_active = !state.overlay.mmenu.shop_active
  },
  hideAlert: (state, store) => {
    state.overlay.browser_alert = false
    localStorage.setItem('alertClosed', true)
  },
}
