import _ from 'lodash'
import $ from 'jquery'

import Base from '../../base'

export default class Default extends Base {
  init() {
    //Bail unless we are on product page
    if (this.d.template.indexOf('product') == -1) return

    //Initialise

    this.bus.$on('vue/loaded', (d) => {
      this.initVariant()
      this.initRelated()
      this.initW2w()
      this.bus.$on('window/resize', (d) => {
        this.onResize(d)
      })
    })

    this.bus.$on('product/buy', (d) => {
      this.buy(d)
    })
    this.bus.$on('cart/item/adding', (d) => {
      this.cartItemAdding()
    })
    this.bus.$on('cart/item/added', (d) => {
      this.cartItemAdded()
    })
  }

  initW2w() {
    const w2w_els = document.getElementsByClassName('w2w_html')

    this.s.product.w2w = _.map(w2w_els, (w2w_html) => {
      const w2w_el = document.createElement('body')
      w2w_el.innerHTML = w2w_html.innerHTML

      const img_els = w2w_el.getElementsByTagName('img')
      const images = _.map(img_els, (_img) => {
        const alt = _img.getAttribute('alt')
        return {
          src: _img.getAttribute('src'),
          alt: alt,
        }
      })

      //Preload gifs
      // setTimeout(()=>{
      //   this.u.preload.files({src: _.map(images, 'src')});
      // },5000)

      while (img_els[0]) img_els[0].parentNode.removeChild(img_els[0])

      return {
        active: false,
        images: images,
        html: w2w_el.innerHTML,
      }
    })
  }

  initVariant() {
    //Select first variant (this could be first variant in stock)
    const variant = this.d.product.variants[0]

    // if(this.d.product.variants.length == 1){
    //Set variant
    this.s.product.selected_variant = _.clone(variant)
    //Set options
    this.s.product.selected_options = _.clone(variant.options)
    // }
  }

  buy(d) {
    const variant_id = this.s.product.selected_variant.id
    const quantity = this.s.product.selected_quantity

    let valid = true
    if (typeof variant_id == 'undefined') valid = false

    if (valid) {
      this.s.product.error = false
      this.bus.$emit('cart/item/add', { id: variant_id, quantity: quantity })
    } else {
      this.s.product.error = 'Please make a selection'
    }
  }

  cartItemAdding() {
    this.s.cart.current_action = 'adding'
  }

  cartItemAdded() {
    this.s.cart.current_action = 'added'
    this.s.header.cart_active = true
    setTimeout(() => {
      this.s.cart.current_action = ''
    }, 2000)
  }

  onResize() {
    const $content = $('[data-ref="content"]')
    const $images = $('[data-ref="image"]')

    this.s.product.images = _.map($images, (v, idx) => {
      const $v = $(v)
      const caption = $v.attr('data-caption')
      const top = $v.offset().top
      const height = $v.height()

      return {
        caption: caption,
        top: top,
        height: height,
        index: idx + 1,
      }
    })

    this.s.product.content_bottom = $content.offset().top + $content.height()

    console.log(this.s.product.content_bottom)
  }

  initRelated() {
    //Search for related products based on tag
    const tags = this.d.product.tags
    const tag = _.filter(tags, (t) => {
      return t.indexOf('related-') !== -1
    })[0]

    if (!tag) return //No point searching without tag
    const handle = tag.replace('related-', '')

    this.u.shopify.products.collection({
      handle: handle,
      callback: (products) => {
        //Filter out the current product, because we don't need it
        const related = []
        if (products.length >= 1) {
          //Remove current product from list
          this.s.product.related = this.formatRelated(products)
        }
      },
    })
  }

  formatRelated(products, include_self = false) {
    return _.chain(products)
      .filter((p) => {
        return include_self ? true : p.id !== this.d.product.id
      })
      .map((p) => {
        p.image_size = this.v.products.getResize(p)
        return p
      })
      .shuffle()
      .slice(0, 2)
      .value()
  }
}
