export default class Mouse {
  // Which HTML element is the target of the event
  mouseTarget(e) {
    let targ
    if (!e) e = window.event
    if (e.target) targ = e.target
    else if (e.srcElement) targ = e.srcElement
    if (targ.nodeType == 3)
      // defeat Safari bug
      targ = targ.parentNode
    return targ
  }

  mousePositionDocument(e) {
    let posx = 0
    let posy = 0

    if (e.pageX || e.pageY) {
      posx = e.pageX
      posy = e.pageY
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
    }

    return {
      x: posx,
      y: posy,
    }
  }

  // Find out where an element is on the page
  // From http://www.quirksmode.org/js/findpos.html
  findPos(obj) {
    let curleft = 0
    let curtop = 0
    if (obj.offsetParent) {
      do {
        curleft += obj.offsetLeft
        curtop += obj.offsetTop
      } while ((obj = obj.offsetParent))
    }
    return {
      left: curleft,
      top: curtop,
    }
  }

  // Mouse position relative to the element
  // not working on IE7 and below
  mousePositionElement(e) {
    const mousePosDoc = this.mousePositionDocument(e)
    const target = this.mouseTarget(e)
    const targetPos = this.findPos(target)

    const posx = mousePosDoc.x - targetPos.left
    const posy = mousePosDoc.y - targetPos.top

    return {
      x: posx,
      y: posy,
    }
  }
}
