import _ from 'lodash'

export default {
  toggle: (state, key, toggle = -1) => {
    const idx = _.findIndex(state.app.toggles, { key: key })
    const val = toggle == -1 ? true : toggle

    if (idx > -1) {
      state.app.toggles[idx].val = val
    } else {
      state.app.toggles.push({ key: key, val: val })
    }
  },
  toggleFooter: (nextTick, s) => {
    s.newsletter.active = !s.newsletter.active

    nextTick(() => {
      if (s.newsletter.active) {
        TweenLite.to(window, 0.3, { scrollTo: { y: '#footer-anchor' } })
      }
    })
  },
  closeAlert: (data, state) => {
    const messageData = JSON.stringify({
      closed: Date.now(),
      message: data.alert_message,
    })

    window.localStorage.setItem('alert', messageData)
    state.app.alert = false
  },
}
