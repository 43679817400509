import _ from 'lodash'

import Base from '../../base'

export default class Default extends Base {
  init() {
    if (this.d.template.indexOf('index') == -1) return

    this.bus.$on('vue/loaded', (d) => {
      this.cursorRef(d)
    })
    this.bus.$on('vue/loaded', (d) => {
      this.preload(d)
    })
    this.bus.$on('vue/loaded', (d) => {
      this.newsletter(d)
    })
    // this.bus.$on('vue/loaded', () => {this.cycleTimer()})
  }

  cursorRef(d) {
    // const ref = this.b.vm.$refs.landing_cursor
    // //Map refs to state
    // this.s.index.cursor = {
    //   $el: $(ref),
    //   $landing: $(ref).closest('.landing'),
    //   el: ref,
    //   x: -9999,
    //   y: -9999,
    // }
  }

  preload(d) {
    let images = _.map(this.d.index.backgrounds)
    images = images.concat(_.map(this.d.index.cursors))

    this.u.preload.files({ src: images })
  }

  newsletter() {
    const storage = localStorage.getItem('newsletter_overlay')
    const hide_time = !storage ? false : storage

    //Don't show if shown in last two weeks
    if (hide_time) {
      const current_time = Date.now()
      const days = 1000 * 60 * 60 * 24
      const time_ago = Math.round((current_time - hide_time) / days)
      if (time_ago < 14) return
    }

    setTimeout(() => {
      //Don't show if overlay already set
      if (this.s.overlay.current != '') return

      //Finally, show
      this.s.overlay.newsletter = true

      //Set hide newsletter
      // this.ls.hide_newsletter = true;
      localStorage.setItem('newsletter_overlay', Date.now())
    }, 10000)
  }

  // cycleTimer() {
  //   let cycleTimeout = setTimeout(_.bind(function interval() {
  //     this.m.index.cycleImages(this.d, this.s);

  //     cycleTimeout = setTimeout(_.bind(interval, this), 3000);
  //   }, this), 3000);
  // }
}
