<template>
  <Accordion class="ShopNavigation">
    <template #default="{ select, selected }">
      <NavigationItem
        v-for="(link, i) in links"
        :key="i + link.url"
        event="mouseenter"
        :link="link"
        :select="select"
        :selected="selected"
        class="navLink"
      />
    </template>
  </Accordion>
</template>

<script>
import Accordion from './Accordion.vue'
import NavigationItem from './NavigationItem.vue'

export default {
  components: {
    Accordion,
    NavigationItem,
  },

  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.ShopNavigation {
  display: flex;

  button a:after,
  .topLevel:after {
    content: none !important;
  }

  > .NavigationItem {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &:not(:last-child) {
      margin-right: 1ch;

      .roman:after,
      .italic:after {
        content: ',';
      }
    }
  }

  .AccordionItem {
    margin-right: -200px;
    padding-right: 200px;

    .glyph {
      display: none;
    }

    .content {
      width: 0;
      overflow: visible;
    }

    .links {
      width: 200px;
      padding-bottom: 10px;
    }
  }
}
</style>
