<template>
  <div class="slideshow" @click="handleTap">
    <div class="slides">
      <div class="controls">
        <div class="touch" ref="sliderSwipe"></div>
      </div>
      <div class="slide" :style="showHangingSlideStyle(slides, selected, 'start')"></div>
      <div
        class="slide"
        v-for="(slide, slide_idx) in slides"
        :style="showSlideStyle(slide, slide_idx, selected)"
        :key="`slideshow__slide${slide_idx}`"
      ></div>
      <div class="slide" :style="showHangingSlideStyle(slides, selected, 'end')"></div>
    </div>
    <div class="count">
      <span class="current">{{ selected + 1 }}</span> / <span class="total">{{ slides.length }}</span>
    </div>
    <div class="label">
      <span>{{ slides[selected].alt }}</span>
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import _ from 'lodash'
import $ from 'jquery'

export default {
  props: ['slides', 'menu-selection', 'user-selected'],
  data() {
    return {
      pan_offset: 0,
      width: 0,
      selected: 0,
    }
  },
  mounted() {
    //Preload slides
    // this.$nextTick(() => {
    //   const preload = window.app.utils.preload
    //   const images = _.map(this.slides, (v) => {
    //     return v.url
    //   })
    //   preload.files({
    //     src: images,
    //     callback: () => {},
    //   })
    // })

    //Set width
    this.width = $(this.$el).width()
    window.addEventListener('resize', () => {
      this.width = $(this.$el).width()
      console.log(this.width)
    })

    //Handle swiping
    const sliderEl = this.$refs.sliderSwipe
    const hm = new Hammer(sliderEl)

    hm.on('panstart', (ev) => {
      this.panStart(ev)
    })

    hm.on('panmove', (ev) => {
      this.panMove(ev)
    })

    hm.on('panend', (ev) => {
      this.panEnd(ev)
    })
  },
  watch: {
    menuSelection(newSelect, oldSelect) {
      const colour = newSelect.split(' ')[0].toLowerCase()
      const index = _.findIndex(this.slides, (s) => s.alt.toLowerCase().indexOf(colour) !== -1)
      if (index !== -1 && this.userSelected) this.selected = index
    },
  },
  methods: {
    panStart(ev) {
      // console.log(ev.distance);
      this.pan_offset = 0
    },
    panMove(ev) {
      //Max drag as ratio
      const max = 0.6

      //Convert delta X to an -1 - 1 number
      let ratio = ev.deltaX / this.width

      //Constrain the ratio to -0.5 to 0.5;
      if (ratio < -max) ratio = -max
      if (ratio > max) ratio = max

      //Easing should slow the further you drag
      const ease = function (t) {
        return t * (2 - t)
      }
      const abs = Math.abs(ratio) * (1 / max) //ease(Math.abs(ratio) * 2);
      ratio = ratio > 0 ? abs / (1 / max) : -abs / (1 / max)

      //Convert back from ratio to a number
      const offset = ratio * this.width

      this.pan_offset = offset

      //Take over scroll if you have gone too far?
    },
    panEnd(ev) {
      //If we have panned further than X, pan to next slide and set selected slide
      const swipe_distance = 50 //this.width / 6; //Lets use what feels normal to the finger
      if (Math.abs(this.pan_offset) > swipe_distance) {
        if (this.pan_offset < 0) {
          this.nextSlide()
        } else {
          this.prevSlide()
        }
      } else {
        this.resetSlide()
      }
    },
    nextSlide() {
      console.log('nextSlide', this.selected, this.slides.length)
      //Difference between current offset and width
      const start = this.pan_offset
      const end = -this.width
      this.animateOffset(start, end, () => {
        //Set slide and revert pan offset
        this.pan_offset = 0
        const select = this.selected + 1 >= this.slides.length ? 0 : this.selected + 1
        this.selected = select
      })
    },
    prevSlide() {
      console.log('prevSlide', this.selected, this.slides.length)
      //Difference between current offset and width
      const start = this.pan_offset
      const end = this.width
      this.animateOffset(start, end, () => {
        //Set slide and revert pan offset
        this.pan_offset = 0
        const select = this.selected - 1 < 0 ? this.slides.length - 1 : this.selected - 1
        this.selected = select
      })
    },
    resetSlide() {
      //Smoothly go back to 0
      const start = this.pan_offset
      const end = 0
      this.animateOffset(start, end, () => {
        this.pan_offset = 0
      })
    },

    setSlide(slide_idx) {
      //Animate from current slide to whichever slide we are going to
      const start = this.pan_offset
      const end = (this.selected - slide_idx) * this.width + this.pan_offset

      this.animateOffset(start, end, () => {
        this.selected = slide_idx
        this.pan_offset = 0
      })
    },
    animateOffset(start, end, cb) {
      //Convert offset to a sensible number of frames
      const frame_total = 20
      let frames = frame_total
      let active = true

      //Easing function
      const ease = function (t) {
        return t * (2 - t)
      }

      //Get distance as ratio
      const difference = end - start

      //Animate from start to end duraion frames
      const loop = (now) => {
        frames -= 1
        if (!!active) {
          //Get frames as ratio
          const animation_ratio = ease((frame_total - frames) / frame_total)
          const position = start + animation_ratio * difference

          this.pan_offset = position

          if (frames <= 0) {
            active = false
            cb()
          }

          requestAnimationFrame(loop)
        }
      }

      requestAnimationFrame(loop)
    },
    showImage(url, format) {
      //Format dimensions
      const dimensions = '800x1200_crop_center'
      const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
      return [url.slice(0, dot_pos), `_${dimensions}`, url.slice(dot_pos)].join('')
    },
    showSlideStyle(slide, slide_idx, selected) {
      const style = {
        'background-image': `url(${slide.url})`,
      }

      //Add offset to slide styling
      const offset = (slide_idx - selected) * this.width + this.pan_offset
      const transform = `translateX(${offset}px) `
      style.transform = transform

      //Slide translate offset is set by its index * width + its current drag
      return style
    },
    showHangingSlideStyle(slides, selected, start_or_end = 'start') {
      //The hanging slide is always selected - 1 unless -1 < 0
      let hanging_idx
      let offset

      if (start_or_end == 'start') {
        offset = -1 * this.width + this.pan_offset
        hanging_idx = selected - 1 >= 0 ? selected - 1 : slides.length - 1
      } else {
        offset = this.width + this.pan_offset
        hanging_idx = selected + 1 <= slides.length - 1 ? selected + 1 : 0
      }

      //Select slide
      const hanging_slide = slides[hanging_idx]

      //Background style
      const style = { 'background-image': `url(${hanging_slide.url})` }

      //Add offset to slide styling
      const transform = `translateX(${offset}px) `
      style.transform = transform

      //Slide translate offset is set by its index * width + its current drag
      return style
    },
    handleTap(e) {
      const forward = e.clientX > this.width / 2

      if (forward) {
        this.selected !== this.slides.length - 1 ? this.selected++ : (this.selected = 0)
      } else {
        this.selected !== 0 ? this.selected-- : (this.selected = this.slides.length - 1)
      }
    },
  },
}
</script>

<style lang="scss"></style>
