<template>
  <img :src="showImage(ready)" :class="{ active: loaded }" />
</template>

<script>
export default {
  props: ['queue', 'window', 'src', 'size'],
  mounted() {
    this.init()
  },
  data() {
    return {
      ready: false,
      loaded: false,
    }
  },
  methods: {
    init() {
      this.ready = true
    },
    getSrc: (url, format) => {
      const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
      return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
    },
    showImage(ready) {
      if (!ready) return false
      if (this.loaded) return this.getSrc(this.src, this.size)

      const elTop = this.$el.offsetTop
      const scrollTop = this.window.scroll.top.current
      const windowHeight = this.window.resize.height.current
      const lookahead = scrollTop + 2 * windowHeight
      const lookbehind = scrollTop - 2 * windowHeight

      if (elTop < lookahead && elTop > lookbehind) {
        this.loaded = true
        return this.getSrc(this.src, this.size)
      }

      return false
    },
  },
}
</script>

<style lang="scss"></style>
