export default {
  namespaced: true,
  state: () => ({
    settings: null,
  }),
  mutations: {
    setSettings(state, settings) {
      state.settings = settings
    },
  },
}
