import Base from '../../base'

export default class Default extends Base {
  init() {
    // if(this.d.template.indexOf('index') == -1) return;

    this.bus.$on('vue/loaded', (d) => {
      this.setCurrency()
    })
  }

  setCurrency() {
    // this.s.currency.code = (this.ls.currency_code) ? this.ls.currency_code : this.s.currency.base;
    const storedCurrency = localStorage.getItem('currency')
    this.s.currency.code = storedCurrency || this.s.currency.base
  }
}
