export default {
  data() {
    return {
      resizeObserver: null,
    }
  },

  mounted() {
    if ('ResizeObserver' in window) {
      this.resizeObserver = new ResizeObserver((entries) => {
        if (this.onResize) {
          requestAnimationFrame(() => {
            this.onResize(entries[0])
          })
        }
      })
      this.resizeObserver.observe(this.$el)
    }
  },

  destroyed() {
    if ('ResizeObserver' in window) {
      this.resizeObserver.disconnect()
    }
  },
}
