<template>
  <div class="MovingImageV2">
    <div class="container">
      <slot />
    </div>
    <div class="container">
      <video ref="video" v-bind="$attrs" loop muted playsinline>
        <source :src="source" :type="mimeType" />
      </video>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js'
import intersection from '~/mixins/intersection'

export default {
  inheritAttrs: false,

  mixins: [intersection],

  props: {
    mimeType: {
      type: String,
      default: 'video/mp4',
    },
    source: {
      type: String,
      default: '',
    },
  },

  computed: {
    video() {
      return this.$refs.video
    },
  },

  watch: {
    intersected(intersected) {
      intersected ? this.video.play() : this.video.pause()
    },
    // source() {
    //   this.mountStream()
    // }
  },

  mounted() {
    if (/.m3u8/.test(this.source)) {
      this.mountStream()
    }
  },

  methods: {
    mountStream() {
      const video = this.$refs.video

      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.source
      } else if (Hls.isSupported()) {
        const hls = new Hls()

        hls.loadSource(this.source)
        hls.attachMedia(video)
      }
    },
  },
}
</script>

<style lang="scss">
.MovingImageV2 {
  display: grid;
  // justify-content: flex-start;
  // align-items: flex-start;

  > * {
    grid-area: 1 / 1;
  }

  .container {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .container > * {
    object-fit: cover;
  }

  video {
    width: 100%;
  }
}
</style>
