import _ from 'lodash'

export default {
  addQty: (state) => {
    const quantity = state.product.selected_quantity
    state.product.selected_quantity = quantity + 1 > 10 ? 10 : quantity + 1
  },
  subQty: (state) => {
    const quantity = state.product.selected_quantity
    state.product.selected_quantity = quantity - 1 < 1 ? 1 : quantity - 1
  },
  selectOption: (state, data, option, ov, ov_idx) => {
    //Update selected options
    const selected_options = state.product.selected_options
    selected_options[option.type_index] = ov
    state.product.selected_options = [] // Force refresh
    state.product.selected_options = selected_options

    //Format selected options to match the way shopifies variant.options works
    const variant_options = []
    _.each(state.product.selected_options, (ov, idx) => {
      variant_options[idx] = ov
    })

    //Attempt to find variant that the options match
    const selected_variant = _.find(data.product.variants, (v) => {
      const clean_options = _.map(v.options, (vo) => {
        return vo
      })
      return _.isEqual(variant_options, clean_options)
    })

    //Update variant if one is found and not the same as old one
    if (selected_variant) {
      state.product.selected_variant = selected_variant
    }

    // This is here so the mobile slideshow doesn't change from the first slide before the user has interacted with it.
    state.product.user_selected = true
  },
  toggleOptionSelect: (state, optionType) => {
    state.product.selection_group = state.product.selection_group === optionType ? '' : optionType
  },
  disableOptionSelect: (state) => {
    state.product.selection_group = ''
  },
  toggleW2w(w2w, toggle = false) {
    w2w.active = toggle
  },
}
