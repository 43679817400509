import $ from 'jquery'

export default class Default {
  constructor(main, utils) {
    this.u = utils
    this.s = main.state
    this.bus = main.bus

    //Get animation frame
    this.raf = this.u.helpers.requestAnimationFrame()
    this.gdh = this.u.helpers.getDocumentHeight

    this.s.window = {
      $window: $(window),
      loaded: false,
      scroll: {
        top: {},
      },
      resize: {
        width: {},
        height: {},
      },
      document: {
        height: {},
      },
    }

    //Initialise state variables
    this.reset()

    //start loop
    this.loop()

    //force events to fire a few times
    $(window).on('load ready', (e) => {
      this.reset()
    })
    this.reset()

    $(window).on('load ready', (e) => {
      this.s.window.loaded = true
    })
  }

  reset() {
    this.s.window.scroll.top.old = -1
    this.s.window.resize.width.old = -1
    this.s.window.resize.height.old = -1
    this.s.window.document.height.old = -1

    this.s.window.scroll.top.current = 0
    this.s.window.resize.width.current = 0
    this.s.window.resize.height.current = 0
    this.s.window.document.height.current = 0

    this.s.window.scroll.top.direction = 'none'
    this.s.window.resize.width.direction = 'none'
    this.s.window.resize.height.direction = 'none'
  }

  loop() {
    const $window = this.s.window.$window

    //Existing variables
    const old_top = this.s.window.scroll.top.old
    const old_width = this.s.window.resize.width.old
    const old_height = this.s.window.resize.height.old
    const old_document_height = this.s.window.document.height.old

    //New variables
    const new_top = window.pageYOffset
    const new_width = $window.width()
    const new_height = $window.height()
    const new_document_height = this.gdh()

    //Scroll events
    if (old_top != new_top) {
      //Set variables
      this.s.window.scroll.top.current = new_top
      this.s.window.scroll.top.direction = new_top > old_top ? 'down' : 'up'

      //Fire event
      this.scrollPub()

      //Set old variables
      this.s.window.scroll.top.old = new_top
    }

    //Resize events
    if (old_width != new_width || old_height != new_height) {
      //Set variables
      this.s.window.resize.width.current = new_width
      this.s.window.resize.height.current = new_height

      this.s.window.resize.width.direction = new_width > old_width ? 'more' : 'less'
      this.s.window.resize.height.direction = new_height > old_height ? 'more' : 'less'

      //Fire event
      this.resizePub()

      //Set old variables
      this.s.window.resize.width.old = new_width
      this.s.window.resize.height.old = new_height
    }

    //Layout events
    if (old_document_height != new_document_height) {
      //Set variables
      this.s.window.document.height.current = new_document_height

      //Fire event
      this.layoutPub()

      //Set old variables
      this.s.window.document.height.old = new_document_height
    }

    //Loop
    window.requestAnimationFrame((time) => {
      this.loop()
    })
  }

  scrollPub() {
    this.bus.$emit('window/scroll', {
      scroll_top: this.s.window.scroll.top.current,
      scroll_direction: this.s.window.scroll.top.direction,
    })
  }

  resizePub() {
    this.bus.$emit('window/resize', {
      width: this.s.window.resize.width.current,
      height: this.s.window.resize.height.current,
      width_direction: this.s.window.resize.width.direction,
      height_direction: this.s.window.resize.height.direction,
    })
  }

  layoutPub() {
    this.bus.$emit('window/layout', {
      height: this.s.window.document.height.current,
    })
  }
}
