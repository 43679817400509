export default {
  browser_alert: '',
  current: '',
  previous: '',
  menu_styles: {},
  mmenu: {
    shop_active: false,
  },
  newsletter: false,
}
