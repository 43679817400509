<template>
  <div class="AccordionItem" @mouseleave="deselect" @blur="deselect" aria-label="">
    <button type="button" @[event]="handleEvent" @focus="handleEvent">
      <slot name="button" :active="active">
        <span class="buttonText"
          >{{ heading }}<span class="glyph">{{ active ? '–' : '+' }}</span></span
        >
      </slot>
    </button>
    <TransitionHeight>
      <div v-show="active" class="content">
        <slot />
      </div>
    </TransitionHeight>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    event: {
      type: String,
      default: 'click',
    },
    heading: {
      type: String,
      default: '',
    },
    id: {
      type: [Number, String],
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
  },

  methods: {
    deselect() {
      this.select(null)
    },
    handleEvent(e) {
      if (e.type === 'mouseenter' && this.active) return
      this.select(this.id)
    },
  },
}
</script>
