<template>
  <nav class="MobileMenu">
    <div class="inner">
      <NavigationItem
        v-for="(link, i) in links"
        :key="i + link.url"
        event="mouseenter"
        :interactive="false"
        :link="link"
        class="navLink"
      />
    </div>
    <slot />
  </nav>
</template>

<script>
import NavigationItem from './NavigationItem.vue'

export default {
  components: {
    NavigationItem,
  },

  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.MobileMenu {
  --item-spacing: 10px;

  display: flex;
  flex-direction: column;
  max-height: 100vh;
  padding: 80px var(--inset) 40px;
  font-size: 22px;
  line-height: 1;

  .inner {
    flex: 1;
    overflow: auto;
    margin-bottom: 30px;
  }

  .NavigationItem {
    margin: var(--item-spacing) 0;
  }

  .links {
    margin: 10px 0;
    padding-left: 30px;
  }

  .link-currency {
    font-size: inherit;
    line-height: 1;

    li {
      margin: var(--item-spacing) 0;
    }
  }
}
</style>
