import _ from 'lodash'

export default {
  getGroup(d) {
    return Object.keys(d).reduce((acc, v) => {
      console.log(d)
      acc.push({
        group: v,
        regions: d[v].regions,
      })

      return acc
    }, [])
  },
  getRow(d) {
    if (!d.regions) return []

    const sub_regions = Object.keys(d.regions).reduce((acc, v) => {
      acc.push({
        region: v,
        stockists: d.regions[v],
      })

      return acc
    }, [])

    const chunked = _.chunk(sub_regions, 4)

    return chunked
  },
}
