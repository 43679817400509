<template>
  <div class="Accordion">
    <slot :selected="selected" :select="select" />
  </div>
</template>

<script>
// import AccordionItem from './AccordionItem'

export default {
  name: 'Accordion',

  components: {
    // AccordionItem
  },

  data() {
    return {
      selected: null,
    }
  },

  methods: {
    select(id) {
      this.selected = this.selected === id ? '' : id
    },
  },
}
</script>

<style lang="scss">
.AccordionItem {
  overflow-y: hidden;

  button {
    width: 100%;
    font-family: inherit;
  }

  .buttonText {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }

  .glyph {
    display: flex;
    justify-content: center;
    width: 1ch;
  }
}
</style>
