import _ from 'lodash'

export default {
  hasOptions: (data) => {
    return data.product.variants.length > 1
  },
  formatOptions: (data) => {
    const options = []

    _.each(data.product.options, (ot, ot_idx) => {
      options[ot_idx] = {
        type: ot,
        type_index: ot_idx,
      }

      options[ot_idx].values = _.chain(data.product.variants)
        .map((variant, variant_idx) => {
          //Format sizes as numbers
          return variant.options[ot_idx]
        })
        .uniq()
        .value()
    })

    return options
  },
  showOptionClass: (state, option, option_value) => {
    const ot_index = option.type_index
    const selected_options = state.product.selected_options

    return selected_options[ot_index] && selected_options[ot_index] == option_value ? 'active' : ''
  },
  colorCount: (data) => {
    const colours = _.filter(data.product.variants, (v) => {
      return v.available
    })

    return colours && colours.length > 1 ? `Available in ${colours.length} colors` : ''
  },
}
