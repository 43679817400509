function hasItems(data) {
  // if (!data.cart.items) return false
  return data.cart.items.length > 0
}

function showItemCount(data) {
  return data.cart.item_count
}

function showItemPrice(s, v, li) {
  return v.currency.showMoney(s, v, li.price)
}

function showItemTotal(s, v, li) {
  return v.currency.showMoney(s, v, li.line_price)
}

function showLineItems(lineItems) {
  return lineItems.filter((lineItem) => lineItem.product.type !== 'Insurance')
}

export default {
  hasItems,
  showItemCount,
  showItemPrice,
  showItemTotal,
  showLineItems,
}
